html,
body {
  padding: 0px;
  margin: 0px;
  font-family: @dmr;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root {
  height: 100%;
}

// Fix: safari 100% height
#root > .rs-container {
    display: flex;
    height: 100%;

    .rs-content {
        flex-basis: 0;
        min-height: 0;
    }

    .Layout__container {
        background-color: @lok-app-white;
        padding: 1.5rem;
        height: 100%;
        overflow: auto;
    }
}

h1,h2,h3,h4,h5,h6 {
  font-family: @psb;
  margin-bottom: 5px;
  font-weight: 600;
  color: black;
}

h1 {
  font-size: 32px;
  line-height: 36px;
  margin-top: 40px;
}

h2 {
  font-size: 22px;
  line-height: 33px;
  margin-bottom: .5rem;
}

h3 {
  font-size: 18px;
  line-height: 25px;
}

h6 {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  font-family: @pm;
}

a {
  font-family: @pr;
  font-size: 10px;
  line-height: 15px;
  text-decoration: underline;
}

p {
  margin-top: 5px;
  margin-bottom: 15px;
}

img {
  user-select: none;
}

ul {
  list-style-type: none;
  padding: 0px;
}

picture {
  display: block;
  user-select: none;
}
