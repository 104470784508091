@import '../variables.less';

.Login__container {
    height: 100%;
    background-color: @lok-background;
    overflow: auto;

    img {
        margin-bottom: 2rem;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }
}
