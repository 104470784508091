@import '../../variables.less';

@input-color: #535353;
@input-border: rgba(56, 56, 56, 0.5);
@input-border-focus: #383838;

label {
  color: @text-color;
  font-size: 13px;
  font-family: @pm;
  line-height: 19px;

  &.is-invalid {
    color: @error-color;
  }
}

.rs-input-group {
    margin-top: .5rem;
}

.rs-input-group.is-invalid {
    .rs-input-group-addon {
        color: @error-light-color;
    }

    input {
        border: 1px solid rgba(255, 0, 0, 0.5);
        color: @error-light-color;
    }
}

.invalid-feedback {
    color: @error-color;
    font-family: @dmb;
    font-size: 10px;
    line-height: 12px;
}

.rs-form-group {
    margin-bottom: 1.5rem;
}
