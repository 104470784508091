@import '../variables.less';

.Support__p {
  font-family: @dmb;
  font-size: 14px;
  color: @lok-app-black-alt;
}

.Support__p--subtitle {
  font-size: 14px;
  color: @lok-app-black-subtitle;
}
