@import '../../variables.less';

.text-muted {
  color: #757575;
}

.text-alt {
  color: @lok-app-text-alt;
}

.text-center {
  text-align: center;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-danger {
  color: @lok-app-danger;
}

.text-success {
  color: @lok-app-success;
}

.subtitle {
  font-family: @pr;
}

.text-copy {
  text-align: center;
  margin-top: 25px;
}
