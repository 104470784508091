@import '../../variables.less';

.Reservation__nav {
    position: sticky !important;
    top: -1.5rem;
    z-index: 5;
    background: white;
    margin-top: 1.25rem;
}

.Reservation__list {
    flex: 1 1 auto;

    small {
        font-size: 10px;
        line-height: 13px;
    }

    h6 {
        color: @lok-app-black-alt;
        margin-bottom: 0;
    }

    .rs-list-item button {
        padding: 0;
        width: 100%;
        text-align: left;
        background: none;
        outline: none;
    }
}

.Reservation__list--loading {
    .rs-list-item {
        padding-bottom: 5px;
    }
}

table.Reservation__details {
    width: 100%;
    font-size: 14px;

    tr > td:first-child {
        font-weight: 500;
        color: @lok-app-black-alt;
    }

    tr > td:last-child {
        text-align: right;
    }
}
