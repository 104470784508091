@import '../../variables.less';

.Location__container {
    padding: .5rem;
    h6 {
        font-weight: 700;
    }
    p {
        margin-bottom: 0px;
    }
}
