@import '../../variables.less';

#logo {
  width: 92px;
  height: 41px;
  margin: auto;
  display: block;
}

.Nav__item {
  width: 100%;
  height: 57px;
  background: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  padding: 0px 1rem;
  margin-bottom: 5px;
  font-family: @pm;
  font-size: 16px;
  color: @lok-app-white;
  text-decoration: none;
  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    svg {
      margin-right: 10px;
      font-size: 25px;
      color: 'blue';
    }
  }
}

.Nav__item.menu__item {
  &:hover, &.active {
    background: @lok-primary;
    color: @lok-app-white;
    text-decoration: none;
  }
}

.Nav--logout {
  width: auto;
  position: absolute;
  bottom: 15px;
  left: 0.5rem;
  &:hover {
    color: @lok-app-white;
    text-decoration: underline;
  } 
}

.rs-icon.icon-menu {
  color: @lok-primary;
  font-size: 30px;
}

.flex-navbar {
  height: 100%;
}