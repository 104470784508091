// https://github.com/rsuite/rsuite/blob/master/src/styles/variables.less
@include './variables';

@base-color: @lok-primary;
@body-bg: @lok-app-white;

@error-color: @lok-primary;
@error-light-color: rgba(255, 0, 0, 0.5);

@info-color: #2196f3;
@info-light-color: #e9f5fe;

@success-color: @lok-app-success;
@success-light-color: #edfae1;

@warning-color: #ffb300;
@warning-light-color: #fff9e6;

@text-color: @lok-app-text-color;
@font-size-base: 12px;

@link-color: #FF8473;
@link-hover-color: @lok-app-red;

// Alerts
@alert-padding: 13px;
@alert-error-icon-color: white;
@alert-close-btn-padding: 0px;
@alert-max-width: 400px;
@alert-error-bg: @error-color;

// Navbar
@nav-bar-default-bg:  @lok-background;
@sidenav-default-bg: @lok-background;

.rs-alert-container {
    width: 100%;

    .rs-alert-item {
        width: 80%;
    }

    .rs-alert-error .rs-alert-item-content {
        color: white;
        max-width: 100%;
    }

    .rs-alert-item-close {
        top: 16px;
    }
}

// Nav & Navbar
@nav-item-active-font-color: @lok-app-red;
@nav-subtle-waterline-active-color: @lok-app-red;
@nav-item-font-default-color: #666262;

.rs-nav-item-content {
    text-align: center;
    font-size: 12px;
    line-height: 16px;
    font-family: @pm;
}

// Sidenav
.rs-navbar.rs-navbar-default {
  height: 60px;
  padding: 5px 1rem;
}

.rs-sidenav.rs-sidenav-collapse-out {
  display: none;
}

.rs-sidenav.rs-sidenav-collapse-in {
  width: 100%;
  height: calc(100% - 60px);
  z-index: 9999;
  position: absolute;
  padding: 0.5rem 1.5rem;
}

.rs-sidenav.rs-sidenav-collapse-in {
  .rs-sidenav-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
}

// Error message

.rs-message-error {
    background: @lok-app-red;
    color: white;

    .rs-message-btn-close, .rs-message-icon-wrapper > .rs-icon {
        color: white !important;
    }
}
