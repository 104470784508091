@import '../../variables.less';

.Modal__overlay {
   background-color: rgba(77, 76, 76, 0.81) !important;
}

.Modal__container {
   box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
   border-radius: 6px;
   margin: 10px auto;
   min-width: 355px;
}

.Modal__button--close {
   fill: @lok-background;
   outline: none;
   top: 16px;
}
