@import '../../variables.less';

@btn-border-radius-base: 4px;

button.rs-btn {
    font-family: @psb;
    font-weight: 600;
}

.rs-btn-ghost {
    border: 2px solid #535353;
    border-radius: 3px;
    color: #535353;

    &:hover {
        color: @text-color;
        border-color: @text-color;
    }
}

.btn__icon {
    background-color: transparent;
    padding: 0px;
    margin: 5px 0px 0px 0px;
    &:active, &:focus {
        border: none;
        outline: none;
    }
}